<template>
  <div class="list-organizer" v-if="items">
    <span>{{ $t("reorderTag") }}</span>
    <draggable
      v-model="items"
      :options="dragOptions"
      :move="onMove"
      @change="onChange"
    >
      <transition-group name="flip-list" tag="ul">
        <li
          class="list-item flex-container"
          v-for="(item, index) in items"
          :key="`item: ${item.id} ${index}`"
        >
          <span>{{ item[options.display][locale] }}</span>
          <!-- <img v-if="!item.number || item.number > 0" :src="dragHandlerIcon" /> -->
          <img :src="dragHandlerIcon" />
        </li>
      </transition-group>
    </draggable>
  </div>
</template>
<script>
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
import draggable from "vuedraggable";

export default {
  name: "ListOrganizer",
  mixins: [ComponentSetterMixin],
  components: {
    draggable,
  },
  computed: {
    dragHandlerIcon() {
      return require("@/assets/icons/ic_drag_handler.svg");
    },
    dragOptions() {
      return {
        animation: 0,
        group: this.options.model,
        ghostClass: "ghost",
      };
    },
    items: {
      get() {
        return this.component[this.options.model];
      },
      set(value) {
        let payload = {
          component: this.component,
        };
        payload[this.options.model] = value;
        this.$store.commit(this.options.updateMutation, payload);
      },
    },
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      // return (
      //   (!relatedElement || !relatedElement.fixed) &&
      //   !draggedElement.fixed &&
      //   draggedElement.lessonTypeId !=
      //     this.$config.soaConfig.lessonTypes.overview.id &&
      //   draggedContext.futureIndex > 0
      // );
      return relatedElement || draggedElement;
    },
    onChange({ moved }) {
      if (
        !this.options.organizeAction
        // ||
        // (moved.newIndex <= 0 &&
        //   moved.element.lessonTypeId !==
        //     this.$config.soaConfig.lessonTypes.overview.id)
      )
        return;
      let itemToUpdate = this.items.find((e) => e.id == moved.element.id);
      if (itemToUpdate) {
        itemToUpdate.order = moved.newIndex;
        itemToUpdate.oldOrder = moved.oldIndex;
      }
      this.$store.dispatch(this.options.organizeAction, itemToUpdate);
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list-organizer {
  span {
    height: auto;
  }
  > span:nth-child(1) {
    margin-left: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .list-item {
    flex-direction: row;
    height: 40px;
    width: 248px;
    margin-left: 16px;
    span {
      margin: 0;
      color: rgba(0, 0, 0, 0.6);
      font-family: Roboto;
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.4px;
      line-height: 16px;
      text-align: left;
      align-self: center;
      cursor: default;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
    }
    img {
      align-self: center;
      margin-left: auto;
      margin-right: 16px;
      display: none;
      &:hover {
        cursor: -webkit-grab;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      img {
        display: initial;
      }
    }
    :global(.ghost),
    :global(.sortable-chosed) {
      img {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }
  }
  .ghost {
    img {
      cursor: grabbing !important;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
}
</style>


