<template>
    <div class="teks-list column">
        <teks-setter v-for="(teks, index) in teksList" :key="index"
        :question="component" :component="teks"/>
        <button class="btn second" @click="addTeks">{{$t('newTeksTag')}}</button>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
import TeksSetter from './TeksSetter.vue'
export default {
    name:'TeksListSetter', 
    mixins:[ComponentSetterMixin],
    components:{TeksSetter},
    computed:{
        teksList(){
            return this.component.teks
        }
    }, 
    methods:{
        addTeks(){
            this.$store.dispatch('addTeksToQuestion', this.component)
        }
    }
}
</script>
<style lang="less">
.teks-list{
    .btn{
        margin: 8px auto;
    }
}
</style>


