<template>
  <div class="text-content flex-container">
    <span>{{ $t("essentialQuestionTag") }}</span>
    <input
      type="text"
      style="color: #000"
      v-model="question"
      :placeholder="$t('essentialQuestionPlaceHolder')"
    />
    <audio-upload
      :key="'esa' + component.id"
      :options="audioOptions"
      :component="essentialQuestion"
    />
  </div>
</template>
<script>
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
import AudioUpload from "@/components/BackOffice/ComponentSetters/AudioUpload.vue";
export default {
    name:'EssentialQuestionSetter', 
    mixins:[ComponentSetterMixin], 
    components:{
        'audio-upload': AudioUpload
    }, 
    computed:{
      essentialQuestion(){
          return this.component[this.options.model]
      },
      question:{
          get(){
              return this.essentialQuestion.question[this.locale]
          }, 
          set(value)
          {
              this.$store.commit('setBuilderComponentPropertyValue',{
                  component: this.component[this.options.model], 
                  propertyName: 'question', 
                  value: value, 
                  language: this.locale})
          }
      }, 
      audioOptions(){
          return {name: 'audio-upload', model: 'audio'}
      }, 
      deleteIcon(){
          return require('@/assets/icons/ic_delete.svg')
      },
      audioIcon(){
          return require('@/assets/icons/ic_audio.svg')
      }
    },
    created(){
        if(!this.component[this.options.model]){
          this.setComponentPropertyValue({audio:{}, question: {id: 0}}, this.options.model)
        }

        this.question = this.question ? this.question : ''
        if(this.component[this.options.model].audio) {
            let essentialQuestion ={...this.component[this.options.model]}
            // essentialQuestion.audio = {}
            this.setComponentPropertyValue(essentialQuestion, this.options.model)
        }
    }
};
</script>

<style lang="less" scoped>
.text-content {
  width: 264px;
  flex-direction: column;
  margin-top: 16px;
  height: auto;
}

span {
  margin-left: 16px;
  height: 16px;
  width: auto;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 16px;
  text-transform: uppercase;
}
input {
  border: none;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.38);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.4px;
  line-height: 16px;
  padding-left: 10px;
}

input {
  margin: 0 auto;
  margin-top: 16px;
  height: 40px;
  width: 232px;
  background-color: #ffffff;
}

.audio-upload.flex-container {
  align-content: center;
  align-items: center;
  position: relative;
  .audio {
    height: 24px;
    width: 24px;
  }

  span:first-of-type {
    height: 16px;
    color: rgba(0, 0, 0, 0.6);
    width: 20px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 16px;
    margin-left: 4px;
    margin-top: -1px;
    text-transform: capitalize;
  }

  span:nth-child(3) {
    height: 16px;
    max-height: 16px;
    max-width: 200px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto";
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-overflow: ellipsis;
    margin-right: auto;
    overflow: hidden;
    margin-top: -1px;
    margin-left: 24px;
    text-align: left;
  }
  button,
  input {
    cursor: pointer;
  }
}

.delete-icon {
  margin-left: auto;
  margin-right: 16px;
  cursor: pointer;
}

.audio-upload {
  width: 264px;
  height: 56px;
}

.audio {
  height: 32px;
  width: 40px;
  margin-left: 21px;
}
:global(.th-btn) {
  height: 26px;
  width: 146px;
}

.upload-btn-wrapper {
  cursor: pointer;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  width: 100%;
  height: 100%;
}
</style>


