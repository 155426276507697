var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "color-setter" }, [
    _c("div", { staticClass: "properties-section flex-container" }, [
      _c("div", {
        staticClass: "background-color",
        style: { backgroundColor: _vm.selectedColor }
      }),
      _c("div", { staticClass: "property-control" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedColor,
                expression: "selectedColor"
              }
            ],
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedColor = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.colorOptions, function(option) {
            return _c(
              "option",
              { key: option.text, domProps: { value: option.value } },
              [_vm._v("\n          " + _vm._s(option.text) + "\n        ")]
            )
          })
        ),
        _c("label", { attrs: { for: "image-width" } }, [
          _vm._v(_vm._s(_vm.$t("fontColorTag")))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }