var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-content row" }, [
    _c("input", {
      directives: [
        { name: "model", rawName: "v-model", value: _vm.url, expression: "url" }
      ],
      class: { "invalid-field": !_vm.isUrlValid },
      attrs: { type: "text", placeholder: _vm.options.placeholder },
      domProps: { value: _vm.url },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.url = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }