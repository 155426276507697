<template>
  <div class="column heightAuto" :class="[addLayer && showAudio ? 'map-point ' : '']">
    <audio-upload
      v-if="showAudio"
      :key="'audio' + component.id"
      :component="component"
      :options="audioOptions"
    />
    <div v-if="addLayer">
      <image-upload
        v-for="image in component.images"
        :key="image.id"
        :component="{ imageId: image }"
        :remove="removeImage"
        :update="updateImage"
        :options="imageOptions"
      />
      <image-upload
        v-if="showAddImage"
        :component="{ imageId: null }"
        :add="addImage"
        :options="imageOptions"
      />
    </div>
   <div v-if="showDisclaimer">
      <div class="child">
          <input class="titles"  :placeholder="$t('imageDisclaimerPlaceHolder')" v-bind:style="{ width:250 + 'px',marginLeft:5 + 'px' }" v-model="content" />
      </div>
   </div>
  </div>
</template>
<script>
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
import AudioUpload from "@/components/BackOffice/ComponentSetters/AudioUpload.vue";
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
export default {
  name: "GlobalConnectionsPointSetter",
  mixins: [ComponentSetterMixin],
  props: {
    addLayer: Boolean,
    showDisclaimer:Boolean,
    showAddImage: Boolean,
    showAudio: Boolean,
  },
  components: {
    AudioUpload,
    ImageUpload,
  },
  computed: {
    content:{
       get(){
               return this.component.content[this.locale]
            },
            set(value){
                this.setComponentPropertyValue(value, 'content',this.locale)
            }
    },
    audioOptions() {
      return this.component.setters.find((e) => e.name === "audio-upload");
    },
    imageOptions() {
      return { name: "image-upload", text: "addImageText", model: "imageId" };
    },
  },
  methods: {
    addImage(imageId) {
      this.$store.commit("addImageIdToRoutePoint", {
        point: this.component,
        imageId: imageId,
      });
    },
    removeImage(imageId) {
      this.$store.commit("removeImageIdToRoutePoint", {
        point: this.component,
        imageId: imageId,
      });
    },
    updateImage(oldImgId, newImgId) {
      this.$store.commit("updateImageIdToRoutePoint", {
        point: this.component,
        oldImgId: oldImgId,
        newImgId: newImgId,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.map-point {
  min-height: 112px;
  margin-top: 8px;
  margin-top: 0px !important;
  span {
    max-width: 80px;
    margin-left: 16px;
  }
  .delete-icon {
    margin-left: auto;
    margin-right: 16px;
    cursor: pointer;
  }
  .child{
    display: flex;
    flex: 1;
    .titles {
        min-width: 657px;
        margin-top: 0px;
        margin-left: 0px;
        width: 100% 
      }
  }
  
}
.heightAuto {
  min-height: auto;
}
</style>

