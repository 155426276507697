<template>
    <div class="catalog-legend flex-container">
        <div class="catalog-header flex-container">
            <span>{{header}}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PanelLegend', 
    computed:{
        header(){
            return this.$store.state.isContentSectionActive ? this.$t('propertiesTag') : this.$t('componentsTag')
        },
        legend(){
            return this.$store.state.isContentSectionActive 
                ? this.$t('propertiesPanelLegend') : this.$t('catalogLegend')
        },
        infoIcon(){
            return require('@/assets/icons/info_outline.svg')
        }, 
    }
}
</script>
<style lang="less">

.catalog-legend{
	height: 56px;
	width: 264px;
    background-color: rgba(0,0,0,0.05);
    align-content: center;

    span {
        align-self: center;
        height: 16px;
        width: auto;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto';
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-left: 18px;
        text-overflow: initial;
        overflow: initial;
    }
    img{
        margin-left: 14px;
    }
    
    .catalog-header {
        margin-left: auto;
        height: 100%;
        width: 100%;
        span{
            height: 16px;
            min-width: 101px;
            color: rgba(0,0,0,0.6);
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-align: center;
            margin: auto;
            text-transform: uppercase;
        }
    }
}

</style>