var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-details" },
    [
      _c("image-upload", {
        attrs: {
          component: _vm.component,
          remove: _vm.removeImageDetails,
          options: _vm.options
        }
      }),
      _c("check-box", {
        attrs: {
          component: _vm.component,
          options: { text: _vm.$t("shadow"), model: "shadow" }
        }
      }),
      _c("audio-upload", {
        key: "image-audio" + _vm.component.id,
        attrs: { component: _vm.component, options: { model: "audio" } }
      }),
      _c("title-setter", {
        key: "image-title" + _vm.component.id,
        attrs: { component: _vm.component, options: _vm.titleOptions }
      }),
      _c("title-setter", {
        key: "image-disclaimer" + _vm.component.id,
        attrs: { component: _vm.component, options: _vm.disclaimerOptions }
      }),
      _c("title-setter", {
        key: "image-caption-margin" + _vm.component.id,
        attrs: { component: _vm.component, options: _vm.captionMarginOptions }
      }),
      _c("title-setter", {
        key: "image-caption-bottom-margin" + _vm.component.id,
        attrs: {
          component: _vm.component,
          options: _vm.captionBottomMarginOptions
        }
      }),
      _c("title-setter", {
        key: "image-caption" + _vm.component.id,
        attrs: { component: _vm.component, options: _vm.captionOptions }
      }),
      _c("audio-upload", {
        key: "caption-audio" + _vm.component.id,
        attrs: { component: _vm.component, options: _vm.audioSetterOptions }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }