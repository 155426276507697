var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row ml-0 mr-0 justify-center" }, [
        _c("div", { staticStyle: { "margin-right": "10px" } }, [
          _vm._v(_vm._s(_vm.$t("choiceTag")))
        ]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedRoutePoint,
                expression: "selectedRoutePoint"
              }
            ],
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedRoutePoint = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.choices, function(choice) {
            return _c(
              "option",
              { key: choice.id, domProps: { value: choice } },
              [_vm._v(_vm._s(choice.choiceNumber))]
            )
          })
        )
      ]),
      _vm.selectedRoutePoint
        ? _c("point", { attrs: { component: _vm.selectedRoutePoint } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }