var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-content flex-container" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.content,
          expression: "content"
        }
      ],
      staticClass: "custom-scrollbar",
      attrs: {
        name: "content",
        placeholder: _vm.options.placeholder,
        rows: "5",
        cols: "30"
      },
      domProps: { value: _vm.content },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.content = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }