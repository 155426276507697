var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "language-setter row" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("english")))]),
      _c("span", [_vm._v("/")]),
      _c("span", [_vm._v(_vm._s(_vm.$t("spanish")))]),
      _c("div", { staticClass: "language-button-control flex-container" }, [
        _c(
          "div",
          {
            staticClass: "switch-button",
            class: { enabled: _vm.enabled },
            on: { click: _vm.toggle }
          },
          [_c("div", { staticClass: "button flex-container" })]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }