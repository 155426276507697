var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column heightAuto",
      class: [_vm.addLayer && _vm.showAudio ? "map-point " : ""]
    },
    [
      _vm.showAudio
        ? _c("audio-upload", {
            key: "audio" + _vm.component.id,
            attrs: { component: _vm.component, options: _vm.audioOptions }
          })
        : _vm._e(),
      _vm.addLayer
        ? _c(
            "div",
            [
              _vm._l(_vm.component.images, function(image) {
                return _c("image-upload", {
                  key: image.id,
                  attrs: {
                    component: { imageId: image },
                    remove: _vm.removeImage,
                    update: _vm.updateImage,
                    options: _vm.imageOptions
                  }
                })
              }),
              _vm.showAddImage
                ? _c("image-upload", {
                    attrs: {
                      component: { imageId: null },
                      add: _vm.addImage,
                      options: _vm.imageOptions
                    }
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.showDisclaimer
        ? _c("div", [
            _c("div", { staticClass: "child" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.content,
                    expression: "content"
                  }
                ],
                staticClass: "titles",
                style: { width: 250 + "px", marginLeft: 5 + "px" },
                attrs: { placeholder: _vm.$t("imageDisclaimerPlaceHolder") },
                domProps: { value: _vm.content },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.content = $event.target.value
                  }
                }
              })
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }