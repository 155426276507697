import PropertiesPanel from '@/components/BackOffice/PropertiesPanel.vue'
export default{
    components:{
        PropertiesPanel
    },
    computed: {
        currentSection(){
            return this.$store.state.isContentSectionActive
                 ? 'content-section' : 'layout-section'
        }
    },
    created(){
        this.$store.commit('setBuilderName', this.$options.name)
        this.$store.commit('updatePreviewModeStatus', false)
        this.$store.commit('updateCurrentBackOfficeComponent', null)
    }
}