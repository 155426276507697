var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items
    ? _c(
        "div",
        { staticClass: "list-organizer" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("reorderTag")))]),
          _c(
            "draggable",
            {
              attrs: { options: _vm.dragOptions, move: _vm.onMove },
              on: { change: _vm.onChange },
              model: {
                value: _vm.items,
                callback: function($$v) {
                  _vm.items = $$v
                },
                expression: "items"
              }
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "flip-list", tag: "ul" } },
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: "item: " + item.id + " " + index,
                      staticClass: "list-item flex-container"
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(item[_vm.options.display][_vm.locale]))
                      ]),
                      _c("img", { attrs: { src: _vm.dragHandlerIcon } })
                    ]
                  )
                })
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }