var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c("div", { staticClass: "audio-upload column" }, [
        _vm.propertyName
          ? _c("span", [_vm._v(_vm._s(_vm.$t(_vm.propertyName)))])
          : _vm._e(),
        _c("div", { staticClass: "container-fluid" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("img", {
                staticClass: "audio",
                attrs: { src: _vm.audioIcon }
              }),
              _c("span", [_vm._v(_vm._s(_vm.locale))]),
              !_vm.audioName
                ? [
                    _c("div", { staticClass: "upload-btn-wrapper" }, [
                      _c("button", { staticClass: "th-btn disabled" }, [
                        _vm._v(_vm._s(_vm.$t("addAudioTag")))
                      ]),
                      _c("input", {
                        staticStyle: { "margin-top": "0px" },
                        attrs: { type: "file", for: "audio" },
                        on: { change: _vm.onFileChange }
                      })
                    ])
                  ]
                : [
                    _c("span", [_vm._v(_vm._s(_vm.audioName))]),
                    _c("img", {
                      staticClass: "delete-icon",
                      attrs: { src: _vm.deleteIcon },
                      on: { click: _vm.removeAudio }
                    })
                  ]
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }