<template>
    <div class="teks-setter row">
        <input type="text" v-model="content" :placeholder="'TEKS...'">
        <img :src="deleteIcon" class="delete-icon"  @click="removeTeks"/>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'TeksSetter', 
    mixins:[ComponentSetterMixin],  
    props:{question: Object},
    computed:{
        content:{
            get(){
                return this.component.content
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'content')
            }
        }
    }, 
    methods:{
        removeTeks(){
            this.$store.dispatch('removeTeksFromQuestion',{
                question: this.question, 
                teks: this.component
            })
        }
    }
}
</script>

<style lang="less">
.teks-setter{
    padding: 4px 16px;
    box-sizing: border-box; 
    input{
        width: 180px;
        margin-right: 16px;
        margin-left: 36px;
        border: none;
        color: rgba(0,0,0,0.6);
        background-color: #FFFFFF;
        font-family: 'Roboto';
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
    }
}
</style>


