var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "properties-panel panel flex-container" },
    [
      _c("panel-legend"),
      _c("language-setter"),
      _c(
        "div",
        { staticClass: "properties-container custom-scrollbar" },
        [
          _vm.component
            ? _vm._l(_vm.optionsList, function(options, index) {
                return _c(options.name, {
                  key: "options" + _vm.component.id + index,
                  tag: "component",
                  attrs: { component: _vm.componentData, options: options }
                })
              })
            : _vm._e()
        ],
        2
      ),
      _c(
        "button",
        {
          staticClass: "save-button",
          class: { saving: _vm.saving },
          on: { click: _vm.saveChanges }
        },
        [_vm._v(_vm._s(_vm.saveButtonText))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }