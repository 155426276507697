<template>
    <div class="title-position">
        <label>Title Position</label>
        <div class="d-flex">
        <div>
            <span>Top</span>
            <input type="number" class="input-margin" v-model="titlePositionTop" min="0" max="150" onKeyPress="if(this.value.length==3) return false;">
        </div>
        <div>   
            <span>Left</span>
            <input type="number"  class="input-margin" v-model="titlePositionLeft" min="0" max="500" onKeyPress="if(this.value.length==3) return false;">
        </div>
        </div>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'PositionSetter', 
    mixins:[ComponentSetterMixin],
    computed:{
        titlePositionTop:{
            get(){
              return this.component.titlePositionTop
            },
            set(value){
                value = parseInt(value)
                if(value > 150){
                    value = 150
                }
                this.setComponentPropertyValue(value, "titlePositionTop")
            }
        },
        titlePositionLeft:{
            get(){
                return this.component.titlePositionLeft
            },
            set(value){
                value = parseInt(value)
                if(value > 500){
                    value = 500
                }
                this.setComponentPropertyValue(value, "titlePositionLeft")
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .title-position{
        text-align: center;
    }
    .d-flex{
        display: flex;
        justify-content: center; 
    }
    .input-margin{
        margin: 5px;
    }
</style>