<template>
  <div class="map-setter column">
    <span class="legend">{{ $t(component.text) }}</span>
    <image-upload
      v-for="image in component.images"
      :key="image.id"
      :component="image"
      :remove="removeImageDetails"
      :options="globalImageOptions"
    />
    <point
      v-for="(point, index) in points"
      :key="point.id"
      :component="point"
      :showAddImage="showAddImage(index)"
      :showAudio="showAudio(index)"
      :addLayer="true"
      :showDisclaimer="showDisclaimer(index)"
    />
  </div>
</template>
<script>
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
import ImageDetailsService from "@/services/ImageDetailsService.js";
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
import GlobalConnectionsPointSetter from "@/components/BackOffice/ComponentSetters/GlobalConnectionsPointSetter.vue";
import CheckBox from "@/components/BackOffice/ComponentSetters/CheckBox.vue";
import AudioUpload from "@/components/BackOffice/ComponentSetters/AudioUpload.vue";
export default {
  name: "GlobalConnectionsSetter",
  mixins: [ComponentSetterMixin],
  components: {
    point: GlobalConnectionsPointSetter,
    ImageUpload,
    CheckBox,
    AudioUpload,
  },
  data() {
    return {
      selectedRoutePoint: "",
    };
  },
  computed: {
    points() {
      return this.component.routePoints;
    },
    globalImageOptions() {
      return {
        name: "image-upload",
        text: "addImageText",
        model: "imageId",
      };
    },
    mapImageOptions() {
      // return {
      //   name: "image-upload",
      //   text: "addImageText",
      //   model: "imageId",
      // };
      return this.globalImageOptions;
    },
    imageTitleOptions() {
      // return {
      //   name: "image-upload",
      //   text: "addImageText",
      //   model: "imageId",
      // };
      return this.globalImageOptions;
    },
    audioOptions() {
      return this.component.setters.find((e) => e.name === "audio-upload");
    },
  },
  methods: {
    newImageDetails(imageId) {
      let newImageDetails = {
        imageId: imageId,
        paragraphId: this.component.id,
        position: "inherit",
        shadow: false,
        originalSize: false,
      };
      ImageDetailsService.linkImageToParagraph(newImageDetails).then(
        (response) => {
          newImageDetails.id = response.data.id;
          this.$store.commit("addImageToComponent", {
            component: this.component,
            image: newImageDetails,
          });
        }
      );
    },
    removeImageDetails(imageId) {
      let imageDetails = this.component.images.find(
        (e) => e.imageId == imageId
      );
      ImageDetailsService.removeImageFromParagraph(imageDetails.id).then(() => {
        this.$store.commit("removeImageFromComponent", {
          component: this.component,
          imageId: imageDetails.id,
        });
      });
    },
    deleteComponent() {
      this.$store
        .dispatch("removeRoutePoint", {
          map: this.component,
          point: this.selectedRoutePoint,
        })
        .then(() => (this.selectedRoutePoint = null));
    },
    showAddImage(i) {
      let addImageTobeShownAt = [0, 1, 2];
      return addImageTobeShownAt.includes(i) &&
        this.component.routePoints[i].images.length == 0
        ? true
        : false;
    },
    showDisclaimer(i){
        let addImageTobeShownAt = [4];
      return addImageTobeShownAt.includes(i) 
        ? true
        : false;
    },
    showAudio(i) {
      let audioTobeShownAt = [2, 3];
      return audioTobeShownAt.includes(i);
    },
  },
};
</script>
<style lang="less">
.map-setter {
  > .row {
    margin-top: 8px;
    span {
      align-self: center;
      margin-right: 16px;
      margin-left: 16px;
      text-transform: uppercase;
    }
    .delete-icon {
      margin-left: auto;
      margin-right: 16px;
    }
  }
}
</style>

