<template>
    <div class="images-list-setter">
        <component v-for="(image, index) in component.images" :key="options.child + index" 
         :component="image" :options="childOptions"
         @onRemove="removeImageDetails" :remove="removeImage" :is="options.child"/>
         
        <image-upload v-if="component.displaySuplementaryImageUpload || component.images.length < 1" 
            :options="childOptions" :add="newImageDetails" :component="component" :remove="removeImage"/>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
import ImageUpload from '@/components/BackOffice/ComponentSetters/ImageUpload.vue'
import ImageDetails from '@/components/BackOffice/ComponentSetters/ImageDetails.vue'
import ImageDetailsService from '@/services/ImageDetailsService.js'
import Factory from "@/utils/ContentFactory.js";

export default {
    name: 'ImagesListSetter', 
    mixins:[ComponentSetterMixin],
    components:{
        'image-upload' :ImageUpload, 
        ImageDetails,
        Factory
    },
    computed:{
        childOptions(){
            return {text:'addImageText', model:'imageId'}
        }
    },
    methods:{
        newImageDetails(imageId){
            let newImageDetails= {
                imageId: imageId,
                paragraphId: this.component.id,
                position: 'inherit',
                shadow: false,
                originalSize: false,
                audio:{},
                caption: new Factory.CreateTextContent(),
                captionAudio: new Factory.CreateAudio(),
                title: new Factory.CreateTextContent(),
                disclaimer: new Factory.CreateTextContent(),
                captionMargin: 0,
                marginBottom: 0
            }
            ImageDetailsService.linkImageToParagraph(newImageDetails)
            .then(({data})=>{
                newImageDetails.id = data.id;
                newImageDetails.caption.id = data.caption.id;
                newImageDetails.title.id = data.title.id;
                newImageDetails.disclaimer.id = data.disclaimer.id;
                this.$store.commit('addImageToComponent',
                {component :this.component, image: newImageDetails })
            }, () => {
                this.$store.commit('addSnackBarNotification', {
                            message: this.$t("failedToUploadImage"),
                            dismissable: false
                        });
            });
        },
        removeImageDetails(id){
            ImageDetailsService.removeImageFromParagraph(id)
                .then(()=>{
                    this.$store.commit('removeImageFromComponent',
                        {component :this.component, imageId: id });
                })
        },
        removeImage(imageId) {
            let imageDetails = this.component.images.find(e => e.imageId == imageId)
            ImageDetailsService.removeImageFromParagraph(imageDetails.id)
                .then(() => {
                    this.$store.commit('removeImageFromComponent',
                        { component: this.component, imageId: imageDetails.id });
                })
        }
        
    }
}
</script>

