var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "padd-response" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("response")))]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.responseText,
              expression: "responseText"
            }
          ],
          staticClass: "inp-width",
          attrs: { type: "text", placeholder: _vm.$t("response") },
          domProps: { value: _vm.responseText },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.responseText = $event.target.value
            }
          }
        })
      ]),
      _c("audio-upload", {
        key: "audio" + _vm.component.id,
        attrs: { component: _vm.component, options: _vm.audioOptions }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }