var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background-setter" }, [
    _c("span", [_vm._v(_vm._s(_vm.$t(_vm.propertyName)))]),
    _c("div", { staticClass: "properties-section flex-container" }, [
      _c("div", {
        staticClass: "background-color",
        style: { backgroundColor: _vm.background, opacity: _vm.opacity / 100 }
      }),
      _c("div", { staticClass: "property-control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.background,
              expression: "background"
            }
          ],
          class: { "invalid-field": !_vm.isBackgroundColorValid },
          attrs: { type: "text" },
          domProps: { value: _vm.background },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.background = $event.target.value
            }
          }
        }),
        _c("label", { attrs: { for: "background-input" } }, [_vm._v("HEX")])
      ]),
      _c("div", { staticClass: "property-control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.opacity,
              expression: "opacity"
            }
          ],
          attrs: { type: "number", min: "0", max: "100" },
          domProps: { value: _vm.opacity },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.opacity = $event.target.value
            }
          }
        }),
        _c("label", { attrs: { for: "background-opacity" } }, [
          _vm._v(_vm._s(_vm.$t("opacityTag")))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }