<template>
  <div class="text-content flex-container">
    <textarea
      v-model="content"
      class="custom-scrollbar"
      name="content"
      :placeholder="options.placeholder"
      rows="5"
      cols="30"
    ></textarea>
  </div>
</template>
<script>
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
export default {
  name: "DescriptionSetter",
  mixins: [ComponentSetterMixin],
  computed: {
    content: {
      get() {
        return this.component[this.options.model][this.locale];
      },
      set(value) {
        this.setComponentPropertyValue(value, this.options.model, this.locale);
      },
    },
  }
};
</script>

<style lang="less" scoped>
.text-content {
  width: 264px;
  flex-direction: column;
  margin-top: 16px;
  height: auto;
}

textarea {
  border: none;
  resize: none;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.38);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.4px;
  line-height: 16px;

  margin: 8px auto;
  min-height: 72px;
  height: auto;
  width: 232px;
}
</style>


