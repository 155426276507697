var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background-setter" }, [
    _c("span", [_vm._v("SIZE")]),
    _c("div", { staticClass: "properties-section flex-container" }, [
      _c("div", { staticClass: "property-control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.width,
              expression: "width"
            }
          ],
          attrs: { type: "number" },
          domProps: { value: _vm.width },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.width = $event.target.value
            }
          }
        }),
        _c("label", [_vm._v("width size")])
      ]),
      _c("div", { staticClass: "property-control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.height,
              expression: "height"
            }
          ],
          attrs: { type: "number" },
          domProps: { value: _vm.height },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.height = $event.target.value
            }
          }
        }),
        _c("label", [_vm._v("Height size")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }