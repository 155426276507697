<template>
  <div class="checkbox-setter flex-container">
    <img :src="checkBoxIcon" @click="checkStatus = !checkStatus" />
    <span>{{ $t(options.text) }}</span>
  </div>
</template>
<script>
import TexasHistoryButton from "@/common/TexasHistoryButton.vue";
export default {
  name: "CheckBox",
  components: {
    "texas-history-button": TexasHistoryButton,
  },
  props: {
    component: Object,
    options: Object,
  },
  methods: {
    setBuilderComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.paragraph,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
    setComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
  computed: {
    isParagraph() {
      return this.component && this.component.paragraph ? true : false;
    },
    checkBoxIcon() {
      return this.checkStatus
        ? require("@/assets/icons/ic_check_box.svg")
        : require("@/assets/icons/ic_check_box_blank.svg");
    },
    checkStatus: {
      get() {
        return this.isParagraph
          ? this.component.paragraph[this.options.model]
          : this.component[this.options.model];
      },
      set(value) {
        this.isParagraph
          ? this.setBuilderComponentPropertyValue(value, this.options.model)
          : this.setComponentPropertyValue(value, this.options.model);
      },
    },
  },
};
</script>

<style lang="less">
.checkbox-setter {
  margin-top: 16px;
  img {
    width: 24px;
    height: 24px;
    margin-left: 22px;
    cursor: pointer;
    align-self: center;
  }
  span {
    height: 16px;
    width: 99px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-align: left;
    margin: auto 8px;
    align-self: center;
    text-transform: capitalize;
  }
}
</style>


