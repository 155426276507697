var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-setter column" },
    [
      _c("span", { staticClass: "legend" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.text)))
      ]),
      _vm._l(_vm.component.images, function(image) {
        return _c("image-upload", {
          key: image.id,
          attrs: {
            component: image,
            remove: _vm.removeImageDetails,
            options: _vm.globalImageOptions
          }
        })
      }),
      _vm._l(_vm.points, function(point, index) {
        return _c("point", {
          key: point.id,
          attrs: {
            component: point,
            showAddImage: _vm.showAddImage(index),
            showAudio: _vm.showAudio(index),
            addLayer: true,
            showDisclaimer: _vm.showDisclaimer(index)
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }