import { render, staticRenderFns } from "./TextAlignmentSetter.vue?vue&type=template&id=77fe96e9&"
import script from "./TextAlignmentSetter.vue?vue&type=script&lang=js&"
export * from "./TextAlignmentSetter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\checkout\\TexasHistory-US\\agent\\_work\\1\\s\\TexasHistory-Frontend\\TexasHistory\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('77fe96e9', component.options)
    } else {
      api.reload('77fe96e9', component.options)
    }
    module.hot.accept("./TextAlignmentSetter.vue?vue&type=template&id=77fe96e9&", function () {
      api.rerender('77fe96e9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BackOffice/ComponentSetters/TextAlignmentSetter.vue"
export default component.exports