<template>
  <div class="color-setter">
    <div class="text-center" style="text-align: center;padding: 15px;">
      <label for="Assessment Type">Assessment Type</label>
      <div class="assessment-type-select">
      <select v-model="assessmentTypeId" style="width: 100%!important;" class="assessment-type-select">
          <option
            v-for="type in assessmentTypes"
            :key="type.name"
            :value="type.value"
          >
            {{ type.name }}
          </option>
      </select>
      </div>
    </div>
  </div>
</template>
<script>
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
export default {
  name: "AssessmentGroupSetter",
  mixins: [ComponentSetterMixin],
  data: function () {
    return {
      selected: "",
      assessmentTypes:[
        {
          name:'GP',
          value: 1,
        },
        {
          name:'IP',
          value: 2,
        },
         {
          name:'No Assessment',
          value: 3,
        }
      ]
    };
  },
  methods: {
    setBuilderComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.paragraph,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
  computed: {
    isParagraph() {
      return this.component && this.component.paragraph ? true : false;
    },
    assessmentTypeId:{
      get(){
        return this.isParagraph 
        ? this.component.paragraph.groupHeaderType
        : this.component.groupHeaderType
      },
      set(value){
        this.isParagraph
          ? this.setBuilderComponentPropertyValue(value, "groupHeaderType")
          : this.setComponentPropertyValue(value, "groupHeaderType")
      }
    }

  },
  mounted(){
  }
};
</script>

<style lang="less" scoped>
.background-color {
  align-self: center;
  margin-right: 11px;
  height: 16px;
  width: 24px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.assessment-type-select{
  margin-top: 10px;
}

</style>


