var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "title-position" }, [
    _c("label", [_vm._v("Title Position")]),
    _c("div", { staticClass: "d-flex" }, [
      _c("div", [
        _c("span", [_vm._v("Top")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.titlePositionTop,
              expression: "titlePositionTop"
            }
          ],
          staticClass: "input-margin",
          attrs: {
            type: "number",
            min: "0",
            max: "150",
            onKeyPress: "if(this.value.length==3) return false;"
          },
          domProps: { value: _vm.titlePositionTop },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.titlePositionTop = $event.target.value
            }
          }
        })
      ]),
      _c("div", [
        _c("span", [_vm._v("Left")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.titlePositionLeft,
              expression: "titlePositionLeft"
            }
          ],
          staticClass: "input-margin",
          attrs: {
            type: "number",
            min: "0",
            max: "500",
            onKeyPress: "if(this.value.length==3) return false;"
          },
          domProps: { value: _vm.titlePositionLeft },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.titlePositionLeft = $event.target.value
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }