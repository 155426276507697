var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "color-setter" }, [
    _c(
      "div",
      {
        staticClass: "text-center",
        staticStyle: { "text-align": "center", padding: "15px" }
      },
      [
        _c("label", { attrs: { for: "Assessment Type" } }, [
          _vm._v("Assessment Type")
        ]),
        _c("div", { staticClass: "assessment-type-select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.assessmentTypeId,
                  expression: "assessmentTypeId"
                }
              ],
              staticClass: "assessment-type-select",
              staticStyle: { width: "100%!important" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.assessmentTypeId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.assessmentTypes, function(type) {
              return _c(
                "option",
                { key: type.name, domProps: { value: type.value } },
                [_vm._v("\n          " + _vm._s(type.name) + "\n        ")]
              )
            })
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }