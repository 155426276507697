<template>
    <div class="background-setter">
        <span>SIZE</span>
        <div class="properties-section flex-container">
            <div class="property-control">
                <input type="number" v-model="width">
                <label >width size</label>
            </div>
            <div class="property-control">
                <input type="number" v-model="height">
                <label >Height size</label>
            </div>
        </div>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'ImageSizeSetter', 
    mixins:[ComponentSetterMixin],  
    props:{
        details: Object
    },
    data: function(){
        return{
            imageDetails: ''
        }
    },
    computed:{
        height:{
            get(){
                return parseInt(this.imageDetails.height)  || 200
            }, 
            set(value){
                this.imageDetails.height = value
                this.$store.commit('updateImageDetailsComponent', 
                {
                    component : this.component,
                    image: this.imageDetails, 
                    property: 'height', 
                    value: value
                })
            }
        }, 
        width:{
            get(){
                return parseInt(this.imageDetails.width) || 200
            }, 
            set(value){
                this.imageDetails.width = value
                this.$store.commit('updateImageDetailsComponent', 
                {
                    component : this.component,
                    image: this.imageDetails, 
                    property: 'width', 
                    value: value
                })
            }
        }
    }, 
    created(){
        this.imageDetails ={
            ...this.details
        }
    }
}
</script>

<style lang="less" scoped>

.properties-section{
    .property-control:nth-child(2)
    {
        margin-left: 8px;
        select{
            margin-left: 8px;

        }
    }
}

</style>


