var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-content flex-container" },
    [
      _vm.options.propertyName
        ? _c("span", [_vm._v(_vm._s(_vm.options.propertyName))])
        : _vm._e(),
      !_vm.options.displayAsTextArea
        ? [
            _vm.inputType === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.title,
                      expression: "title"
                    }
                  ],
                  attrs: {
                    placeholder: _vm.$t(_vm.options.placeholder),
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.title)
                      ? _vm._i(_vm.title, null) > -1
                      : _vm.title
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.title,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.title = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.title = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.title = $$c
                      }
                    }
                  }
                })
              : _vm.inputType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.title,
                        expression: "title"
                      }
                    ],
                    attrs: {
                      placeholder: _vm.$t(_vm.options.placeholder),
                      type: "radio"
                    },
                    domProps: { checked: _vm._q(_vm.title, null) },
                    on: {
                      change: function($event) {
                        _vm.title = null
                      }
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.title,
                        expression: "title"
                      }
                    ],
                    attrs: {
                      placeholder: _vm.$t(_vm.options.placeholder),
                      type: _vm.inputType
                    },
                    domProps: { value: _vm.title },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.title = $event.target.value
                      }
                    }
                  })
          ]
        : [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.title,
                  expression: "title"
                }
              ],
              staticClass: "custom-scrollbar",
              attrs: {
                rows: "6",
                placeholder: _vm.$t(_vm.options.placeholder)
              },
              domProps: { value: _vm.title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.title = $event.target.value
                }
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }