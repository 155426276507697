var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-content flex-container" },
    [
      _c("span", [_vm._v(_vm._s(_vm.$t("essentialQuestionTag")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question,
            expression: "question"
          }
        ],
        staticStyle: { color: "#000" },
        attrs: {
          type: "text",
          placeholder: _vm.$t("essentialQuestionPlaceHolder")
        },
        domProps: { value: _vm.question },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.question = $event.target.value
          }
        }
      }),
      _c("audio-upload", {
        key: "esa" + _vm.component.id,
        attrs: { options: _vm.audioOptions, component: _vm.essentialQuestion }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }