<template>
    <div class="container-fluid">
        <div class="language-setter row">
            <span>{{$t('english')}}</span>
            <span>/</span>
            <span>{{$t('spanish')}}</span>
            <div class="language-button-control flex-container">
                <div class="switch-button" :class="{ enabled: enabled }" @click="toggle">
                    <div class="button flex-container"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SwitchButton from '@/common/SwitchButton.vue'
import apiClient from '@/services/ApiClient.js'

export default {
    name:'LanguageSetter', 
    components:{
        SwitchButton
    }, 
    data: function(){
        return{
            enabled: false
        }
    },
    methods:{
        toggle: function() {
            this.enabled = !this.enabled
            var language = this.enabled 
                ?  'es'
                :  'en'
            this.$store.commit('updateLanguage', language)          
            apiClient.defaults.headers['language'] = this.$i18n.locale
        },
    }, 
    watch: {
        enabled: function() {
            this.$store.dispatch("configurationSettings", {
                lessonType: "LESSONTYPE",
                language: this.locale,
            });
        }
    },
    created(){
        this.enabled = this.$i18n.locale != 'en'
    }
}
</script>

<style lang="less">

.language-setter{
    margin-top: 16px;
    span{
        height: 16px;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto';
        text-transform: uppercase;
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0.4px;
        line-height: 16px;
        text-align: left;
        align-self: center;
        margin-top: 8px;
        margin-left: 4px;
        &:nth-child(1){
            color: rgb(58, 77, 253);
            margin-left: 16px;
        }
        &:nth-child(3){
           color: #00C853;
        }
    }
.language-button-control{
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    margin-left: 76px;
    margin-right: 16px;
  .switch-button {
        @height: 12px;
        @switch-button-color: rgb(58, 77, 253);
        @switch-button-border-thickness: 2px;
        @switch-transition: all 0.5s ease-in-out;
        @switch-is-rounded: true;

        height: @height;
        width: 40px;
        background-color:  rgba(58, 77, 253, 0.3);
    
        border-radius: if(@switch-is-rounded, @height, 0);
        transition: @switch-transition;

        @button-side-length: 20px;    
        cursor: pointer;

        .button {
            height: 20px;
            margin-top: -4px;
            width: @button-side-length;
            border-radius: if(@switch-is-rounded, @button-side-length, 0);
            background: @switch-button-color;
            transition: @switch-transition;
        }

        &.enabled {
            .button {
                transform: translateX(
                calc(@button-side-length)
                );
                background-color:#00C853;
            }
            background-color:  rgba(0, 200, 83, 0.3);
        }
    }


    }
}
</style>


