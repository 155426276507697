<template>
    <div class="audio-upload column" v-if="model">
        <span v-if="propertyName">{{$t(propertyName)}}</span>
        <div class="container-fluid">
            <div class="row">
                <img :src="audioIcon" class="audio" />
                <span>{{locale}}</span>
                <template v-if="!audioName">
                    <div class="upload-btn-wrapper">
                        <button class="th-btn disabled">{{$t('addAudioTag')}}</button>
                        <input type="file" for="audio" class="" @change="onFileChange" style="margin-top: 0px;">
                    </div>
                </template>
                <template v-else>
                    <span>{{audioName}}</span>
                    <img :src="deleteIcon" class="delete-icon"  @click="removeAudio"/>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
import AudioService from '@/services/AudioService.js'

export default {
    name:'AudioUpload', 
    mixins:[ComponentSetterMixin], 
    components:{
        AudioService
    },
    methods: {
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            if(!files[0].type.includes('audio'))
            {
                this.$store.commit('addSnackBarNotification',{message: this.$t('wrongFileFormatAlert')})
                return;
            }
            this.setAudioProperty(this.locale, files[0].name)
            this.audio= files[0]
            let formData = new FormData();
            formData.append('file', files[0]);  
            this.uploadAudio(formData)
        },
        removeAudio: function () {
            this.$store.commit('updateLoadingStatus', true)
            setTimeout(()=>{
            AudioService.removedAudio(this.locale, this.model.id)
            .then(() =>{
                this.setAudioProperty(this.locale, '')
                this.$store.commit('updateLoadingStatus', false)
            })
            .catch(()=> {
                this.$store.commit('addSnackBarNotification',{
                    message: 'failed to remove audio!',
                    dismissable: true
                })
                this.$store.commit('updateLoadingStatus', false)
            })
            }, 500)
            
        }, 
        uploadAudio(data){
            this.$store.commit('updateLoadingStatus', true)
            setTimeout(()=>{
                AudioService.uploadAudio(data, this.locale, this.model.id)
                .then((response) =>{
                    this.setAudioProperty('id', response.data.id)
                    this.$store.commit('updateLoadingStatus', false)
                })
                .catch(()=> {
                    this.$store.commit('updateLoadingStatus', false)
                    this.removeImage()
                })

            }, 1000)
        },
        setAudioProperty(property, value){
            let audioData = {...this.model}
            audioData[property] =value
            if(this.component && this.component.paragraph) {
                this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.component.paragraph, propertyName: this.options.model, value: audioData})
            } else {
                this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.component, propertyName: this.options.model, value: audioData})
            }
        }
    }, 
    computed:{
        deleteIcon(){
            return require('@/assets/icons/ic_delete.svg')
        },
        audioIcon(){
            return require('@/assets/icons/ic_audio.svg')
        }, 
        audioName(){
            return this.model[this.locale]
        }, 
        model(){
            return this.component && this.component.paragraph
            ? this.component.paragraph[this.options.model]
            : this.component[this.options.model]
        },
        propertyName(){
            return this.options.propertyName;
        }
    }, 
    mounted(){
        if(this.component && this.component.paragraph && !this.component.paragraph[this.options.model])
        {
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.component.paragraph, propertyName: this.options.model, value: {}})
            return 
        }
        if(!this.component[this.options.model]) {
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.component, propertyName: this.options.model, value: {}})
            return
        }
        if(this.audioName)
            return
        this.setAudioProperty('es', this.model.realNameSpanish)
        this.setAudioProperty('en', this.model.realNameEnglish)
    },
    watch:{
        "component": function(){
            if(this.component[this.options.model]){
                if(this.model.realNameSpanish)
                    this.setAudioProperty('es', this.model.realNameSpanish);

                if(this.model.realNameEnglish)
                    this.setAudioProperty('en', this.model.realNameEnglish);
            }
            else{
                this.setComponentPropertyValue({}, this.options.model);
            }
        }
    }
}
</script>

<style lang="less" scoped>

.audio-upload .row{
    align-content: center;
    align-items: center;
    position: relative;
    .audio{
        height: 24px;
        width: 24px;
    }

    span:first-of-type{
        height: 16px;
        color: rgba(0,0,0,0.6);
        min-width: 20px;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 16px;
        margin-left: 4px;
        margin-top: -1px;
        text-transform: uppercase;
    }

    span:nth-child(3){
        height: 16px;
        max-height: 16px;
        max-width: 146px;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto';
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0.4px;
        line-height: 16px;
        text-overflow: ellipsis; 
        margin-right: auto;
        overflow: hidden;
        margin-top: -1px;
        // margin-left: 24px;
        text-align: left;
    }
    button, input{
        cursor: pointer;
        text-transform: uppercase;
    }
}


.delete-icon{
    margin-left: auto;
    margin-right: 16px;
    cursor: pointer;
}

.audio-upload{
    width: 264px;

    .row{
        height: 56px;
    }
    
}

.audio{
	height: 32px;
    width: 40px;
    margin-left: 21px;
}
:global(.th-btn){
    height: 26px;
    width: 146px;
}

.upload-btn-wrapper {
    cursor: pointer;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    font-size: 12px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin-top: 0px!important;
}

</style>


