var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-setter column" },
    [
      _c("span", { staticClass: "legend" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.text)))
      ]),
      _vm.image
        ? _c("image-upload", {
            staticClass: "image-displayed",
            attrs: {
              component: _vm.image,
              remove: _vm.removeImageDetails,
              options: _vm.backgroundMapOptions
            }
          })
        : _c("image-upload", {
            attrs: {
              component: {},
              add: _vm.newImageDetails,
              options: _vm.backgroundMapOptions
            }
          }),
      _vm.overlay
        ? _c("image-upload", {
            staticClass: "image-displayed",
            attrs: {
              component: _vm.overlay,
              remove: _vm.removeImageDetails,
              options: _vm.overlayMapOptions
            }
          })
        : _c("image-upload", {
            attrs: {
              component: {},
              add: _vm.newImageDetails,
              options: _vm.overlayMapOptions
            }
          }),
      _c("check-box", {
        attrs: {
          component: _vm.component,
          options: { text: "progressive", model: "isProgressiveMap" }
        }
      }),
      _c("check-box", {
        attrs: {
          component: _vm.component,
          options: { text: "horizontal", model: "isHorizontalMode" }
        }
      }),
      _c("check-box", {
        attrs: {
          component: _vm.component,
          options: { text: "displayNumbers", model: "displayStepNumbers" }
        }
      }),
      _c("div", { staticClass: "row ml-0 mr-0" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("step")))]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedRoutePoint,
                expression: "selectedRoutePoint"
              }
            ],
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedRoutePoint = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.points, function(point) {
            return _c("option", { key: point.id, domProps: { value: point } }, [
              _vm._v(_vm._s(point.number))
            ])
          })
        ),
        _vm.selectedRoutePoint
          ? _c("img", {
              staticClass: "delete-icon",
              attrs: { src: _vm.deleteIcon },
              on: { click: _vm.deleteComponent }
            })
          : _vm._e()
      ]),
      _vm.selectedRoutePoint
        ? _c("point", { attrs: { component: _vm.selectedRoutePoint } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }