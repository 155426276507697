<template>
    <div>
        <div class="padd-response">
        <label>{{$t('response')}}</label>
        <input type="text" v-model="responseText" :placeholder="$t('response')" class="inp-width">
    </div>
        <audio-upload
      :key="'audio' + component.id"
      :component="component"
      :options="audioOptions"
    />
    </div>
</template>
<script>
import AudioUpload from "@/components/BackOffice/ComponentSetters/AudioUpload.vue";
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
export default {
    name:'QuestionPointSetter',
     mixins: [ComponentSetterMixin],
     components:{
         AudioUpload
     },
    computed:{
        audioOptions(){
            return {"model":"responseAudio","name":"audio-upload"}
        },
        responseText:{
             get(){
                return this.component.responseText[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'responseText', this.locale)
            }
        },
    }
}
</script>
<style lang="less" scoped>
    .padd-response{
        padding: 10px 10px;
    }
    .inp-width{
        width: 100%;
        margin-top: 10px;
    }
</style>