var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-point column" },
    [
      _c("audio-upload", {
        key: "audio" + _vm.component.id,
        attrs: { component: _vm.component, options: _vm.audioOptions }
      }),
      _vm._l(_vm.component.images, function(image) {
        return _c("image-upload", {
          key: image.id,
          attrs: {
            component: { imageId: image },
            remove: _vm.removeImage,
            update: _vm.updateImage,
            options: _vm.imageOptions
          }
        })
      }),
      _c("image-upload", {
        attrs: {
          component: { imageId: null },
          add: _vm.addImage,
          options: _vm.imageOptions
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }