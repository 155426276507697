<template>
    <div class="color-setter">
        <div class="properties-section flex-container">
            <div class="property-control">
                <select v-model="selectedValue">
                    <option v-for="option in alignmentOptions" :key="option.text"
                         :value="option.value">{{option.text}}</option>
                </select>
                <label for="image-width">{{$t('textAlignmentTag')}}</label>
            </div>
        </div>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'TextAlignmentSetter', 
    mixins:[ComponentSetterMixin],  
    data: function() {
        return{
            selected: '', 
            alignmentOptions:[
                    {text: this.$t('start'), value:'flex-start'},
                    {text: this.$t('center'), value:'center'},]
        }
    },
    methods: {
        setBuilderComponentPropertyValue(value, propertyName = this.options.model, language = null) {
            this.$store.commit('setBuilderComponentPropertyValue',
                { 'component': this.component.paragraph, 'propertyName': propertyName, 'value': value, language: language })
        },
    },
    computed:{
        isParagraph() {
            return this.component && this.component.paragraph ? true : false;
        },
        selectedValue:{
            get(){
                return this.isParagraph ?  this.component.paragraph.alignment : this.component.alignment
            }, 
            set(value){
                this.isParagraph 
                ? this.setBuilderComponentPropertyValue(value, 'alignment')  
                : this.setComponentPropertyValue(value, 'alignment')
            }
        }
    }
}
</script>

