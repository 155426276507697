<template>
    <div class="map-setter column">
        <span class="legend">{{$t(component.text)}}</span>
        <image-upload v-if="image" :component="image" class="image-displayed" :remove="removeImageDetails" :options="backgroundMapOptions"/>
        <image-upload v-else  :component="{}" :add="newImageDetails" :options="backgroundMapOptions"/>
        <image-upload v-if="overlay" :component="overlay" class="image-displayed" :remove="removeImageDetails" :options="overlayMapOptions"/>
        <image-upload v-else  :component="{}" :add="newImageDetails" :options="overlayMapOptions"/>
        <check-box :component="component"  :options="{text:'progressive', model:'isProgressiveMap'}"/>
        <check-box :component="component"  :options="{text:'horizontal', model:'isHorizontalMode'}"/>
        <check-box :component="component"  :options="{text:'displayNumbers', model:'displayStepNumbers'}"/>
        <div class="row ml-0 mr-0">
          
            <span>{{$t("step")}}</span>
            <select v-model="selectedRoutePoint">
                <option v-for="point in points" :key="point.id"
                :value="point">{{point.number}}</option>
            </select>
            <img  class="delete-icon" v-if="selectedRoutePoint"
                :src="deleteIcon" @click="deleteComponent" />
        </div>
        <point v-if="selectedRoutePoint" :component="selectedRoutePoint"/>
    </div>
</template>
<script>
import ImageUpload from '@/components/BackOffice/ComponentSetters/ImageUpload.vue'
import ImageDetailsService from '@/services/ImageDetailsService.js'
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
import LessonPointSetter from '@/components/BackOffice/ComponentSetters/LessonPointSetter.vue'
import CheckBox from '@/components/BackOffice/ComponentSetters/CheckBox.vue'
export default {
    name:'MapSetter', 
    mixins:[ComponentSetterMixin], 
    components:{
        'point':LessonPointSetter, 
        ImageUpload, 
        CheckBox
    }, 
    data(){
        return {
            selectedRoutePoint: ''
        }
    }, 
    computed:{
        points(){
            return this.component.routePoints
        }, 
        image(){
            return this.component.images[0]
        },
        overlay(){
            return this.component.images[1]
        },
        backgroundMapOptions(){
            return {"name": "image-upload",  "text":"backgroundMapText", "model":"imageId"}
        },
        overlayMapOptions(){
            return {"name": "image-upload",  "text":"overlay", "model":"imageId"}
        }
    }, 
    methods:{
        newImageDetails(imageId){
            let newImageDetails= {
                imageId: imageId,
                paragraphId: this.component.id,
                position: 'inherit',
                shadow: false,
                originalSize: false
            }
            ImageDetailsService.linkImageToParagraph(newImageDetails)
            .then((response)=>{
                newImageDetails.id = response.data.id
                this.$store.commit('addImageToComponent',
                {component :this.component, image: newImageDetails })
            })
        }, 
        removeImageDetails(imageId){
            let imageDetails = this.component.images.find(e => e.imageId == imageId)
            ImageDetailsService.removeImageFromParagraph(imageDetails.id)
                .then(()=>{
                    this.$store.commit('removeImageFromComponent',
                        {component :this.component, imageId: imageDetails.id });
                })
        }, 
        deleteComponent(){
            this.$store.dispatch('removeRoutePoint',{
                map: this.component, 
                point: this.selectedRoutePoint
            })
            .then(()=> this.selectedRoutePoint = null)

        }, 
    }
}
</script>
<style lang="less">
.map-setter{
    >.row{
        margin-top: 8px;
        span{
            align-self: center;
            margin-right: 16px;
            margin-left: 16px;
            text-transform: uppercase;
        }
        .delete-icon{
            margin-left: auto;
            margin-right: 16px;
        }
    }
}
</style>

