<template>
<div>
    <div class="row ml-0 mr-0 justify-center">
            <div style="margin-right: 10px">{{$t("choiceTag")}}</div>
            <select v-model="selectedRoutePoint">
                <option v-for="choice in choices" :key="choice.id"
                :value="choice">{{choice.choiceNumber}}</option>
            </select>
        
     </div>
    <point v-if="selectedRoutePoint" :component="selectedRoutePoint"/>
</div>
     
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
import QuestionPointSetter from '@/components/BackOffice/ComponentSetters/QuestionPointSetter.vue'
export default {
    mixins:[ComponentSetterMixin], 
    components:{
        'point': QuestionPointSetter
    },
    name: 'QuestionSetter',
    data(){
        return {
            selectedRoutePoint: ''
        }
    }, 
    computed:{
        choices(){
            return this.component.choices
        }
    },
    methods:{
        deleteComponent(){

        }
    }
}
</script>
<style lang="less" scoped>
    .justify-center{
        justify-content: center;
    }
</style>