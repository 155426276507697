var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teks-setter row" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.content,
          expression: "content"
        }
      ],
      attrs: { type: "text", placeholder: "TEKS..." },
      domProps: { value: _vm.content },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.content = $event.target.value
        }
      }
    }),
    _c("img", {
      staticClass: "delete-icon",
      attrs: { src: _vm.deleteIcon },
      on: { click: _vm.removeTeks }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }