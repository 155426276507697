<template>
    <div class="text-content flex-container">
        <span v-if="options.propertyName">{{options.propertyName}}</span>
        <template v-if="!options.displayAsTextArea">
            <input :type="inputType" v-model="title" :placeholder="$t(options.placeholder)">
        </template>
        <template v-else>
            <textarea class="custom-scrollbar" rows="6" 
                    v-model="title" 
                    :placeholder="$t(options.placeholder)"/>
        </template>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'TitleSetter', 
    mixins:[ComponentSetterMixin],
    computed:{
        isParagraph() {
            return this.component && this.component.paragraph;
        },
        title:{
            get(){
                if(this.isParagraph) {
                    return this.options.type != 'String'
                    ? this.component.paragraph[this.options.model][this.locale]
                    : this.component.paragraph[this.options.model]
                } else {
                    return this.options.type != 'String'
                    ? this.component[this.options.model][this.locale]
                    : this.component[this.options.model]
                }
            }, 
            set(value)
            {
                value = value && (this.options.model === 'captionMargin' || this.options.model === 'marginBottom')
                    ?   parseInt(value) : value
                if(this.options.validator){
                    if(!this.options.validator.validate(value)){
                        let _error = this.$store.state.currentBackOfficeComponent.errors
                                            .find(item => item == this.options.validator.errorMessage);

                        if(!_error)
                            this.$store.commit("addBackOfficeComponentValidationError", 
                                                        this.options.validator.errorMessage);
                    }
                    else{
                        this.$store.commit("removeBackOfficeComponentValidationError", {
                            error: this.options.validator.errorMessage
                        });
                    }
                }

                this.options.type != 'String'
                    ? this.setComponentPropertyValue(value, this.options.model, this.locale)
                    : this.setComponentPropertyValue(value, this.options.model)
            }
        },
        inputType(){
            return this.options.inputType || "text";
        }
    }
}
</script>

<style lang="less">

.panel .text-content{
    width: 264px;
    flex-direction: column;
    margin-top: 16px;
    height: auto;

    input[type="number"]{
        margin-top: 5px;
    }

    input, textarea{
        border: none;
        color: rgba(0,0,0,0.38);
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin: 0 auto;
        margin-top: 16px;
        width: 232px;
        box-sizing: border-box;
    }

    input{
        height: 40px;
    }

    textarea{
        max-height: 96px;
    }
}

</style>


