var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "teks-list column" },
    [
      _vm._l(_vm.teksList, function(teks, index) {
        return _c("teks-setter", {
          key: index,
          attrs: { question: _vm.component, component: teks }
        })
      }),
      _c("button", { staticClass: "btn second", on: { click: _vm.addTeks } }, [
        _vm._v(_vm._s(_vm.$t("newTeksTag")))
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }