<template>
    <div class="image-details">
        <image-upload :component="component" :remove="removeImageDetails" :options="options"/>
        <check-box :component="component" :options="{text:$t('shadow'), model:'shadow'}"/>
        <audio-upload :component="component" :key="'image-audio'+component.id"  :options="{model:'audio'}"/>
        <title-setter :component="component" 
                    :key="`image-title${component.id}`"
                    :options="titleOptions"/>
        <title-setter :component="component" 
                    :key="`image-disclaimer${component.id}`"
                    :options="disclaimerOptions"/>
        <title-setter :component="component" 
                    :key="`image-caption-margin${component.id}`"
                    :options="captionMarginOptions"/>
        <title-setter :component="component" 
                    :key="`image-caption-bottom-margin${component.id}`"
                    :options="captionBottomMarginOptions"/>
        <title-setter :component="component" 
                    :key="`image-caption${component.id}`"
                    :options="captionOptions"/>
        <audio-upload :component="component" 
                    :key="'caption-audio'+component.id"
                    :options="audioSetterOptions" />
    </div>    
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
import ImageUpload from '@/components/BackOffice/ComponentSetters/ImageUpload.vue'
import AudioUpload from '@/components/BackOffice/ComponentSetters/AudioUpload.vue'
import CheckBox from '@/components/BackOffice/ComponentSetters/CheckBox.vue'
import ImageDetailsService from '@/services/ImageDetailsService.js'
import TitleSetter from "@/components/BackOffice/ComponentSetters/TitleSetter.vue";

export default {
    name: 'ImageDetails', 
    mixins:[ComponentSetterMixin],
    components:{
        'image-upload' :ImageUpload, 
        'check-box': CheckBox,
        AudioUpload,
        ImageDetailsService,
        TitleSetter
    }, 
    computed:{
        titleOptions(){
            return {
                model: "title", 
                placeholder: "imageTitlePlaceHolder"
            };
        },
        disclaimerOptions(){
            return {
                model: "disclaimer", 
                placeholder: "imageDisclaimerPlaceHolder"
            };
        },
        captionMarginOptions(){
            return {
                type: 'String',
                inputType: "number",
                model: "captionMargin",
                propertyName: this.$t("imageCaptionMarginPlaceHolder"),
                validator: {
                    validate: function(value){ 
                        let _regExp = /^([1-9][0-9]*)?$/g;
                        return _regExp.test(value); 
                    },
                    errorMessage: this.$t("wrongImageCaptionMarginValue")
                }
            };
        },
        captionBottomMarginOptions(){
            return {
                type: 'String',
                inputType: "number",
                model: "marginBottom",
                propertyName: this.$t("imageCaptionBottomMarginPlaceHolder"),
                validator: {
                    validate: function(value){ 
                        let _regExp = /^([1-9][0-9]*)?$/g;
                        return _regExp.test(value); 
                    },
                    errorMessage: this.$t("wrongImageBottomMarginValue")
                }
            };
        },
        captionOptions(){
            return {
                model: "caption", 
                placeholder: "imageCaptionPlaceHolder",
                displayAsTextArea: true
            };
        },
        audioSetterOptions(){
            return {
                model: "captionAudio",
                propertyName: "captionAudio"
            };
        }
    },
    methods:{
        removeImageDetails(){
            this.$emit('onRemove',this.component.id)
        }
    }
}
</script>
<style lang="less" scoped>

.image-details{
    & /deep/ .text-content{
        margin-top: 0px;
    }
}

</style>



