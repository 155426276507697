var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background-setter" }, [
    _c("span", [_vm._v("SIZE")]),
    _c("div", { staticClass: "properties-section flex-container" }, [
      _c("div", { staticClass: "property-control" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedSize,
                expression: "selectedSize"
              }
            ],
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedSize = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.sizeOptions, function(option) {
            return _c(
              "option",
              { key: option.text, domProps: { value: option.value } },
              [_vm._v(_vm._s(option.text))]
            )
          })
        ),
        _c("label", { attrs: { for: "image-width" } }, [
          _vm._v(_vm._s(_vm.$t("widthSizeTag")))
        ])
      ]),
      _vm.selectedSize != 12
        ? _c("div", { staticClass: "property-control" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedAlignment,
                    expression: "selectedAlignment"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedAlignment = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.alignmentOptions, function(option) {
                return _c(
                  "option",
                  { key: option.text, domProps: { value: option.value } },
                  [_vm._v(_vm._s(option.text))]
                )
              })
            ),
            _c("label", { attrs: { for: "background-opacity" } }, [
              _vm._v(_vm._s(_vm.$t("alignmentTag")))
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }