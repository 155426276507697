var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-setter flex-container" }, [
    _c("img", {
      attrs: { src: _vm.checkBoxIcon },
      on: {
        click: function($event) {
          _vm.checkStatus = !_vm.checkStatus
        }
      }
    }),
    _c("span", [_vm._v(_vm._s(_vm.$t(_vm.options.text)))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }