var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "images-list-setter" },
    [
      _vm._l(_vm.component.images, function(image, index) {
        return _c(_vm.options.child, {
          key: _vm.options.child + index,
          tag: "component",
          attrs: {
            component: image,
            options: _vm.childOptions,
            remove: _vm.removeImage
          },
          on: { onRemove: _vm.removeImageDetails }
        })
      }),
      _vm.component.displaySuplementaryImageUpload ||
      _vm.component.images.length < 1
        ? _c("image-upload", {
            attrs: {
              options: _vm.childOptions,
              add: _vm.newImageDetails,
              component: _vm.component,
              remove: _vm.removeImage
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }