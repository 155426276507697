<template>
  <div class="text-content row">
    <input
      style=""
      type="text"
      v-model="url"
      :placeholder="options.placeholder"
      :class="{ 'invalid-field': !isUrlValid }"
    />
  </div>
</template>
<script>
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
import ValidationHelper from "@/utils/ValidationHelper.js";
export default {
  name: "VideoUrlSetter",
  mixins: [ComponentSetterMixin],
  components: {
    ValidationHelper,
  },
  data() {
    return {
      isUrlValid: true,
      errorMessage: "URL is invalid",
    };
  },
  methods: {
    setBuilderComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.paragraph,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
  computed: {
    isParagraph() {
      return this.component && this.component.paragraph ? true : false;
    },
    url: {
      get() {
        return this.isParagraph
          ? this.component.paragraph[this.options.model][this.locale] || ""
          : this.component[this.options.model][this.locale] || "";
      },
      set(value) {
        this.$store.commit("removeBackOfficeComponentValidationError", {
          error: this.errorMessage,
          component: this.component,
        });
        this.isUrlValid = ValidationHelper.isVideoUrlValid(value);
        if (!this.isUrlValid)
          this.$store.commit(
            "addBackOfficeComponentValidationError",
            this.errorMessage
          );
        this.isParagraph
          ? this.setBuilderComponentPropertyValue(
              value,
              this.options.mode,
              this.locale
            )
          : this.setComponentPropertyValue(
              value,
              this.options.mode,
              this.locale
            );
      },
    },
  },
  watch: {
    "component.paragraph.errors": function (value) {
      let indexofBackgroundError = value.indexOf(this.errorMessage);
      indexofBackgroundError >= 0
        ? (this.isUrlValid = false)
        : (this.isUrlValid = true);
    },
  },
};
</script>
<style lang="less">
.text-content{
  display: contents;
}
.text-content input, .text-content textarea {
    display: block;
    margin-top: 8px;
    // margin-left: 32px !important;
}
</style>

