<template>
    <div class="date-setter row">
        <input type="number" min="1" v-model="year" placeholder="Year"
            :class="{'invalid-field': !year}">
        <select v-model="month">
            <option v-for="(month, index) in months" :key="index"
                    :value="month">{{month}}</option>
        </select>
        <select v-model="day" :class="{'invalid-field': !year}">
            <option v-for="(day, index) in days" :key="index"
                    :value="day">{{day}}</option>
        </select>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name: 'DateSetter', 
    mixins:[ComponentSetterMixin], 
    data(){
        return{
            date: '',
            months:["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"], 
            days:[], 
        }
    },
    computed: {
        year:{
            get(){
                return this.component.year
            },
            set(value){
                let year = parseInt(value)
                if(year === 0)
                    year = 1
                this.setComponentPropertyValue(year, 'year')
                this.getDays()
            }
        },
        month:{
            get(){
                return this.months[this.component.month]
            },
            set(value){
                let monthNumber = this.months.indexOf(value)
                this.setComponentPropertyValue(monthNumber, 'month')
                this.getDays()             
            }
        },
        day:{
            get(){
                return this.component.day
            },
            set(value){
                this.setComponentPropertyValue(value, 'day')
            }   
        }
    },
    methods:{
        getDays(){
            this.days =[]
            let selectedYear = parseInt(this.year)
            if(!Number.isInteger(selectedYear)){
                return
            }
            let daysInMonth = new Date(this.year, this.component.month +1, 0).getDate()
            for (let day = 1; day <= daysInMonth; day++) {
                this.days.push(day)
            }
        }
    }, 
    mounted(){
        this.getDays()
    }

}
</script>
<style lang="less">
.date-setter{
    label{
        align-self: center;
        margin-right: 8px;
    }
    
    input, select{
        align-self: center;
        height: 40px;
        width: 92px;
        max-width: 92px;
        border: 1px solid #CFCFCF;
        background-color: #FFFFFF;

        color: rgba(0,0,0,0.6);
        font-family: 'Roboto';
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0.4px;
        line-height: 16px;
        box-sizing: border-box;
        margin-left: 8px;
    }
    input{
        margin-left: 0;
        box-sizing: border-box;
        width: 76px;
    }
}
</style>


